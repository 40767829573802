/**
 * message modal
 * put class in button .message
 * use jQuery delegated event listener to prevent link problem from 2nd page
 */
$(document).ready(function() {
    "use strict";

    $('div').on('click','.markAsRead',function(event)
    {
        event.preventDefault();

        // get form action from clicked button id
        let markAsReadAction = $(this).attr('id');

        // show delete modal
        $('#markAsReadModal').modal('show');
        // put proper action to the delete form to delete the record
        $('#markAsReadForm').attr('action', markAsReadAction)
    });

});
